@import './components/language-bullets.css';
@import './components/blog-index-list/index.css';

/* Porfolio Header */
.header {
  text-align: center;
  padding-bottom: 1em;
}

.header .avatar {
  border: 1px solid var(--accent-background);
  border-radius: 50%;
  padding: 4px;
}

.header .title {
  margin: 0;
  font-size: 3em;
  /* border-bottom: 1px solid rgba(0,0,0,.1); */
  /* margin-bottom: .25em; */
  /* padding-bottom: .25em; */
}

.header .subtitle {
  color: var(--accent-foreground);
  font-size: .8em;
}

.portfolio figcaption {
  font-size: 0.8em;
  font-weight: unset;
  color: var(--accent-foreground);
}

/* Portfolio grid */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1em;
}

.grid-center-start {
  grid-column-start: 2;
}

.grid-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.markdown-body {
  max-width: 68em;
}


.recent-post-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recent-post-container {
  max-width: 45em;
}

.section-header {
  text-align: center;
}

@media screen and (max-width: 45em) {
  .grid-container,
  .grid-container-2,
  .grid-container-1 {
    grid-template-columns: 1fr;
  }
}

/* Print specifics */
@media print {
  .grid-container > div {
    page-break-inside: avoid;
  }

  .header .subtitle,
  .portfolio figcaption {
    color: var(--accent-foreground);
  }

  .avatar {
    height: 5em;
    width: 5em;
  }
}
